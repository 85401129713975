<template>
  <div>
    <Header :showBackButton="false" class="page-anim1" />

    <section>
      <div class="container">
        <div class="title mt-1 animated fadeInDown">Meus favoritos</div>

        <p class="mt-2" v-show="!loading && recipes.length < 1">
          Você ainda não possui nenhum item em seus favoritos.
        </p>

        <transition enter-active-class="animated zoomIn">
          <div
            v-show="recipes.length > 0"
            class="recipes"
            v-infinite-scroll="getRecipes"
            infinite-scroll-disabled="allRecipesLoaded"
            infinite-scroll-distance="10"
          >
            <div v-for="recipe in recipes" :key="recipe.id" class="recipe-item">
              <div @click="remove(recipe)" class="remove">
                <p>Remover</p>
                <Close :size="18" />
              </div>
              <RecipeCard
                :id="recipe.id"
                :slug="recipe.slug"
                :title="recipe.title"
                :description="recipe.description"
                :image="recipe.coverImage"
                :likes="recipe.likes"
                :showLikes="false"
              />
            </div>
          </div>
        </transition>

        <div
          v-show="loading"
          class="flex-center desktop12 tablet12 mobile12 mt-3"
        >
          <div class="loading2"></div>
        </div>
      </div>
    </section>

    <Navbar class="desktop-hidden" />
  </div>
</template>
<script>
import http from "@/http";
import authentication from "../util/authentication";
import Header from "../components/Header";
import Navbar from "../components/Navbar";
import RecipeCard from "../components/RecipeCard";
import Magnify from "vue-material-design-icons/Magnify.vue";
import Close from "vue-material-design-icons/Close.vue";

export default {
  name: "Home",
  components: {
    Header,
    Navbar,
    RecipeCard,
    Magnify,
    Close,
  },
  data() {
    return {
      loading: false,
      recipes: [],
      take: 18,
      page: 1,
      allRecipesLoaded: false,
    };
  },
  created() {
    this.getRecipes();
  },
  methods: {
    postRemoval(id) {
      http
        .post(`user/favorite?recipeId=${id}`, "", authentication.getHeaders())
        .then((response) => {
          if (response.status === 200) {
            this.$swal({
              toast: true,
              icon: "success",
              title: "Receita removida",
              position: "top-right",
              showConfirmButton: false,
              timer: 4000,
            });
            this.recipes = this.recipes.filter((obj) => obj.id !== id);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    remove(recipe) {
      this.$swal
        .fire({
          icon: "error",
          title: "Remover favoritos",
          text: `Tem certeza que deseja remover a receita "${recipe.title}" do seus favoritos?`,
          confirmButtonText: "Remover",
          confirmButtonColor: "var(--red1)",
          showCancelButton: true,
        })
        .then((result) => {
          if (result.value) {
            this.postRemoval(recipe.id);
          }
        });
    },
    getRecipes() {
      if (this.loading || this.allRecipesLoaded) return;

      this.loading = true;

      http
        .get(
          `user/favorite?take=${this.take}&page=${this.page}&orderBy=timestamp`,
          authentication.getHeaders()
        )
        .then((response) => {
          if (this.page < response.data.pages) {
            this.page++;
          } else {
            this.allRecipesLoaded = true;
          }
          this.recipes = [...this.recipes, ...response.data.list];
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.recipes {
  position: relative;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  gap: 25px;
  margin-top: 30px;
  margin-bottom: 45px;
}

.recipe-item {
  position: relative;
}

.remove {
  position: absolute;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;
  bottom: 2px;
  z-index: 2;
  right: 1em;
  p {
    position: relative;
    color: var(--secondary);
    top: -2px;
  }
  span svg {
    color: var(--secondary);
  }
}

@media screen and (min-width: 1160px) {
}
</style>
